@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .menu-item {
    @apply text-primary-color block rounded py-2 pr-4 pl-3 font-sans text-xl;
  }

  .label-form {
    @apply text-primary-color mt-4 ml-4 p-2 text-lg font-semibold;
  }

  .input-form {
    @apply text-primary-color mx-5 bg-gray-50 p-2 text-lg;
  }

  .card-obter-contrato {
    @apply delay-50 hover: group flex -translate-y-1 rounded-lg text-white transition duration-300 ease-in-out hover:scale-125 hover:bg-[#EBE1FD] hover:text-[#413E3E] hover:shadow-lg;
  }
}

@layer base {
  p {
    @apply mb-4 mr-2 ml-2;
  }

  strong {
    @apply font-bold;
  }

  h1 {
    @apply mt-7 p-1 text-center font-bold;
  }

  h2 {
    @apply mb-5 p-1 text-center font-bold;
  }

  h3 {
    @apply mb-7 p-1 text-center;
  }

  h4 {
    @apply ml-2 mt-60 flex justify-center space-y-4 p-2 text-center font-bold;
  }

  table {
    @apply border-2 border-solid border-black p-4;
  }

  td {
    @apply border-2 border-solid border-black;
  }
}

@layer utilities {
  .primary-color {
    background-color: #44377c;
  }

  .text-primary-color {
    color: #413e3e;
  }

  .yellow {
    background-color: #d6dc40;
  }

  .button-contract {
    color: #43377c;
    background: #d6dc40;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.015em;
    font-style: normal;
    font-family: "Open Sans";
    font-weight: 800;
  }

  .salmon-quare {
    background: #ebe1fd;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }

  .input-payment {
    box-sizing: border-box;
    background: #f5f8fa;
    border: 1px solid #cbd6e2;
    border-radius: 3px;
  }

  .page {
    min-width: 210mm;
    max-width: 210mm;
    min-height: 297mm;
    padding: 20mm;
    margin: 5mm auto;
    border: 1px #d3d3d3 solid;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  }

  .page-md {
    min-width: 210mm;
    max-width: 210mm;
    min-height: 120mm;
    padding: 20mm;
    margin: 5mm auto;
    border: 1px #d3d3d3 solid;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  }

  .subpage {
    padding: 1cm;
    height: 257mm;
  }

  .subpage-md {
    padding: 1cm;
    height: 100mm;
  }
}

.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #9747ff;

  /* Blue */

  border-radius: 50%;
  width: 120px;
  height: 120px;

  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.secondary-color {
  background-color: #7c6bcb;
}

.tab {
  display: none;
}

.wizard {
  max-width: 160mm;
}

.font-montserrat {
  font-family: "Montserrat";
}

.font-roboto {
  font-family: "Roboto";
}

.font-mulish {
  font-family: "Mulish";
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.rectangle {
  position: relative;
  width: 65px;
  height: 180.68px;
  margin-left: -17px;
  top: 520px;
  background: #43377c;
  border-radius: 0px 10px 50px 0px;
}

#picture__input {
  display: none;
}

.picture {
  width: 150px;
  height: 150px;

  border-radius: 50%;
  background: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  border: 2px dashed currentcolor;
  cursor: pointer;
  font-family: sans-serif;
  transition: color 300ms ease-in-out, background 300ms ease-in-out;
  outline: none;
  overflow: hidden;
}

.picture:hover {
  color: #777;
  background: #ccc;
}

.picture:active {
  border-color: turquoise;
  color: turquoise;
  background: #eee;
}

.picture:focus {
  color: #777;
  background: #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.picture__img {
  max-width: 100%;
}

@keyframes open {
  0% {
    background-position: 166% -25%, 220% -69%;
  }

  100% {
    background-position: 120% -5%, 200% -40%;
  }
}

.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;

  margin-bottom: 25px;
}

input[type="file"] {
  display: none;
}

.img-wrap {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .img-wrap {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
  }

  .page {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    padding: 10px;
    margin: 0;
    border: 0;
    box-shadow: 0;
  }
}

.img-wrap-mobile {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
}

.img-upload:before {
  content: "";
  font-size: 90px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgb(247, 247, 247);
}

.img-upload:hover:before {
  opacity: 1;
}
